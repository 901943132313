/* Import the OCR A Font */
@font-face {
  font-family: 'OCR A';
  src: url('./assets/fonts/OCRA.ttf') format('truetype');
}

/* Apply OCR A font globally */
body {
  font-family: 'OCR A', sans-serif; /* Apply the OCR A font across all text */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Hide any overflowing content for a seamless background */
  background-color: #000; /* Dark background to enhance neon effect */
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9); /* Slight transparency */
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;  /* Align items in a vertical column */
  align-items: center;  /* Center horizontally */
  justify-content: center;  /* Center vertically */
  text-align: center;  /* Center text */
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(101, 101, 101, 0.5), 0 2px 10px rgba(0, 0, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.213);
  backdrop-filter: blur(8px);
  color: white;
  z-index: 1;
}

/* Adding the circuit board effect with pseudo-elements */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
              linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
              linear-gradient(90deg, rgba(170, 50, 75, 0.434), rgba(63, 1, 118, 0.399));
  background-size: 50px 50px, 50px 50px, 100%;
  animation: moveBackground 10s linear infinite;
  z-index: 0; /* Make sure the circuit effect is behind the content */
}

@keyframes moveBackground {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

/* Adding a neon line border effect */
.home-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 0, 200, 0.2), rgba(0, 0, 255, 0.2));
  opacity: 0.1;
  z-index: 0;
}

/* Title and subtitles */
.title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(103, 103, 103, 0.7);
}

.subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(0, 0, 255, 0.6);
}

.message {
  font-size: 1.2rem;
  color: #f0f0f0;
}

/* Button styles for a futuristic feel */
button {
  font-family: 'OCR A', sans-serif; /* Ensure buttons also use OCR A */
  font-size: 1rem;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

button:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}
